import { combineReducers } from "redux";
// Reducers
import moviesReducer from "./movies/movies.reducer";
import authReducer from "./auth/auth.reducer";

const rootReducer = combineReducers({
  movies: moviesReducer,
  auth: authReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
