import * as api from "../../../api";
import { MoviesDispatchType, SortOption } from "../../reducers/movies/type";

// Action Types
import {
  ADD_MOVIE,
  ADD_MOVIE_STATUS,
  GET_MOVIES,
  SORT_MOVIES,
} from "./actionTypes";

// REDUX ONLY ACTIONS
export const sortMovies = (sortOption: SortOption) => (
  dispatch: MoviesDispatchType
) => {
  dispatch({ type: SORT_MOVIES, payload: sortOption });
};

//#region ::: API ACTIONS
export const getMovies = () => async (dispatch: MoviesDispatchType) => {
  // console.log("Inside GetMovies action");
  try {
    const res = await api.getMovies();
    // console.log(res);

    const { data } = res;
    dispatch({ type: GET_MOVIES, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const addMovie = (movie_formdata: FormData) => async (
  dispatch: MoviesDispatchType
) => {
  dispatch({ type: ADD_MOVIE_STATUS, payload: "IN PROGRESS" });
  try {
    const res = await api.addMovie(movie_formdata);
    if (res.status === 201) {
      // console.log(res);
      const { data } = res;

      dispatch({ type: ADD_MOVIE, payload: data });
      dispatch({ type: ADD_MOVIE_STATUS, payload: "DONE" });
    } else {
      console.log(res);
      // TODO: DISPATCH ERROR MESSAGE INTO REDUX STORE
    }
    // Todo: Handle Error from Creation
  } catch (error) {
    console.log(error.message);
    dispatch({ type: ADD_MOVIE_STATUS, payload: "FAILED" });
    console.log(error);
  }
};
//#endregion
