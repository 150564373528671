import React, { FC, FormEvent } from "react";
import styled from "styled-components";

const RadioInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  options: Array<string>;
  value: string;
  onChange: (event: FormEvent<HTMLDivElement>) => void;
};

const RadioInput = ({ options, value, onChange }: Props) => {
  return (
    <RadioInputContainer onChange={onChange}>
      {options.map((option) => (
        <li key={option}>
          <input
            id={option}
            type="radio"
            name={"category"}
            value={option}
            defaultChecked={value === option}
          />
          <label htmlFor={option}>{option}</label>
        </li>
      ))}
    </RadioInputContainer>
  );
};

export default RadioInput;
