import React, { FC, useEffect, useState } from "react";
import "./App.css";
import { Route, Switch, useLocation } from "react-router-dom";
// ACTIONS
import { useDispatch, useSelector } from "react-redux";
// Store
import { RootState } from "./store/reducers";
import { getMovies } from "./store/actions/movies/movies.actions";
// COMPONENTS
import Dashboard from "./components/Dashboard/Dashboard";
import AddMovie from "./components/Movies/AddMovie/AddMovie";
import Movies from "./components/Movies/Movies";
import NotFound from "./components/NotFound/NotFound";
import Auth from "./components/Auth/Auth";
import PrivateRoute from "./utils/PrivateRoute";
import { IsTokenValid } from "./utils";

const App: FC = () => {
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(IsTokenValid(dispatch));
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );
  const location = useLocation();

  useEffect(() => {
    setIsAuth(IsTokenValid(dispatch));
  }, [location, isAuthenticated, dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getMovies());
    }
  }, [dispatch, isAuthenticated]);
  return (
    <Switch>
      <Route key={"Auth"} exact path="/" component={Auth} />
      <PrivateRoute
        key={"Dashboard"}
        exact
        isAuth={isAuth}
        path="/Dashboard"
        component={Dashboard}
      />
      <PrivateRoute
        isAuth={isAuth}
        key={"Movies"}
        exact
        path="/Movies"
        component={Movies}
      />
      <PrivateRoute
        isAuth={isAuth}
        key={"AddMovie"}
        exact
        path="/Movies/AddMovie"
        component={AddMovie}
      />

      {/* 404 */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
