import React, { FC, useState } from "react";
import styled from "styled-components";

type Props = {
  options: string[];
  placeholder: string;
  value_name: string;
  values: string[];
  onChange: (values: string[]) => void;
};

const MultiSelect: FC<Props> = ({
  options,
  placeholder,
  value_name,
  values,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // i used a variable to instantly assign value
  var removeClicked = false;

  // console.log("remove clicked >>> ", removeClicked);
  const toggleList = () => {
    if (removeClicked) {
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleRemove = (option: string) => () => {
    removeClicked = true;
    onChange(values.filter((value) => value !== option));
  };

  const onOptionClicked = (option: string) => () => {
    if (values.includes(option)) {
      onChange(values.filter((value) => value !== option));
    } else {
      onChange([...values, option]);
    }
    setIsOpen(false);
  };

  return (
    <div className="multiselect">
      <MultiSelectContainer>
        <MultiSelectHeader
          onClick={toggleList}
          className={isOpen ? "open" : ""}
        >
          {values.length < 1 ? `${placeholder}:` : ""}
          {values?.sort().map((value) => (
            <span className="multiselect__choice" key={Math.random()}>
              <span
                className="multiselect__choice__remove"
                onClick={handleRemove(value)}
              >
                ×
              </span>
              {value}
            </span>
          ))}
        </MultiSelectHeader>

        {isOpen ? (
          <MultiSelectListContainer>
            <MultiSelectList>
              {options.map((option) => (
                <ListItem
                  className={values.includes(option) ? "selected" : ""}
                  onClick={onOptionClicked(option)}
                  key={Math.random()}
                >
                  {values.includes(option) && <RemoveIcon>×</RemoveIcon>}
                  {option}
                </ListItem>
              ))}
            </MultiSelectList>
          </MultiSelectListContainer>
        ) : (
          ""
        )}
      </MultiSelectContainer>
    </div>
  );
};

export default MultiSelect;

const MultiSelectContainer = styled("div")`
  width: 100% !important;
  //   height: 44px;
  margin-bottom: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;
const MultiSelectHeader = styled("div")`
  display: flex;
  align-items: center;

  border: none;
  height: 44px;
  color: #fff;
  //   padding: 10px 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #151f30;
  width: 100%;
  font-size: 14px;
  border-radius: 16px;

  outline: none !important;
  font-variant: normal !important;
  //   cursor: pointer;

  /* display: block; */
  box-sizing: border-box;

  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2f80ed;
    outline: 1px solid #151f30;

    border-radius: 2px;
    cursor: pointer;

    border-left: 16px transparent solid;
    border-right: 16px transparent solid;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background: #151f30;
    border-left: 16px transparent solid;
    border-right: 16px transparent solid;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &.open {
    border-radius: 16px 16px 0px 0px;
  }
  & .multiselect__choice {
    background-color: rgba(47, 128, 237, 0.1);
    border: none;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    // margin-top: 7px;
    color: #fff;
    padding: 0 10px;
    margin-right: 10px;

    & .multiselect__choice__remove {
      color: #2f80ed;
      margin-right: 7px;
      transition: color 0.5s;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      &:hover {
        color: #eb5757;
      }
    }
  }
`;

const RemoveIcon = styled.span`
  margin-right: 7px;
  cursor: pointer;
  font-weight: bold;
`;

const MultiSelectListContainer = styled("div")`
  padding-bottom: 10px;
  padding-right: 10px;
  position: absolute;
  width: 100%;

  background-color: #151f30;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  max-height: 220px;

  z-index: 1051;
`;
const MultiSelectList = styled("ul")`
  display: block;
  padding: 10px 20px;

  left: 0;
  box-sizing: border-box;
  width: 100%;

  height: 100%;
  max-height: 200px;
  overflow-y: auto;

  z-index: 1051;

  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2f80ed;
    outline: 1px solid #151f30;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #151f30;
  }
`;
const ListItem = styled("li")`
  padding: 0;
  line-height: 40px;
  font-size: 14px;
  color: #e0e0e0;
  line-height: 40px;
  display: block;
  transition: 0.5s;
  /* When the value is longer than the space */
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  cursor: pointer;

  &.selected {
    background-color: transparent;
    color: #2f80ed;
    cursor: default;
    transition: color 0.5s;
    &:hover {
      color: #eb5757;
    }
  }
`;
