import {
  createStore,
  applyMiddleware,
  compose,
  Store,
  CombinedState,
} from "redux";
import reduxThunk from "redux-thunk";
import RootReducers from "./reducers";
import {
  AuthActionTypes,
  AuthDispatchType,
  AuthState,
} from "./reducers/auth/type";
import {
  MoviesActionTypes,
  MoviesDispatchType,
  MovieState,
} from "./reducers/movies/type";

const store: Store<
  CombinedState<{
    movies: MovieState;
    auth: AuthState;
  }>,
  // ACTION TYPES: IF THERE ARE MORE, I CAN ADD THEM WITH '|'
  MoviesActionTypes | AuthActionTypes
> & {
  // DISPATCH TYPES: IF THERE ARE MORE, I CAN ADD THEM WITH '|'
  dispatch: MoviesDispatchType | AuthDispatchType;
} = createStore(RootReducers, compose(applyMiddleware(reduxThunk)));

export default store;

// store.ts
export type AppDispatch = typeof store.dispatch;
