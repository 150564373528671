import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import {
  ArrowBackIcon,
  CatalogIcon,
  HomeIcon,
  ItemsAddedIcon,
} from "../../../../images";

const SidebarNav: FC = () => {
  return (
    <>
      {/* Sidebar Nav  */}
      <ul className="sidebar__nav">
        <SidebarNavItem
          itemLink="/Dashboard"
          itemTitle="Dashboard"
          ItemSvgIcon={HomeIcon}
        />

        <SidebarNavItem
          itemLink="/Movies/AddMovie"
          itemTitle="Add Movie"
          ItemSvgIcon={ItemsAddedIcon}
        />
        <SidebarNavItem
          itemLink="/Movies"
          itemTitle="Movies"
          ItemSvgIcon={CatalogIcon}
        />

        <li className="sidebar__nav-item">
          <a
            href="https://watch-tv.jesuseyitan.com"
            target="_blank"
            rel="noreferrer"
            className="sidebar__nav-link"
          >
            <ArrowBackIcon />
            <span>Back to Watch TV</span>
          </a>
        </li>
      </ul>
      {/* end sidebar nav  */}
    </>
  );
};

type NavItemProps = {
  itemLink: string;
  itemTitle: string;
  ItemSvgIcon: () => JSX.Element;
};
const SidebarNavItem: FC<NavItemProps> = ({
  itemLink,
  itemTitle,
  ItemSvgIcon,
}) => (
  <li className="sidebar__nav-item">
    <NavLink
      to={itemLink}
      exact
      // strict
      className="sidebar__nav-link"
      activeClassName="sidebar__nav-link--active"
    >
      <ItemSvgIcon />
      <span>{itemTitle}</span>
    </NavLink>
  </li>
);

export default SidebarNav;
