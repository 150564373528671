import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";
import { UploadIcon } from "../../../../images";

type Prop = {
  value: FileList[0] | null;
  placeholder: string;
  setImage: (file: FileList[0] | null) => void;
};

const ImageInput: FC<Prop> = ({ value, placeholder, setImage }) => {
  const handleSetImage = (e: ChangeEvent<HTMLInputElement>) => {
    setImage(e.target.files instanceof FileList ? e.target.files[0] : null);
  };

  return (
    <div style={{ height: "44px" }}>
      <InputContainer>
        <input
          id="image-input"
          type="file"
          accept=".png, .jpg, .jpeg"
          // value={value}
          onChange={handleSetImage}
          hidden
        />
        <Label htmlFor="image-input">
          <UploadIconWrapper>
            <UploadIcon />
          </UploadIconWrapper>
          <FileValue>
            {value ? `Selected File: ${value.name}` : placeholder}
          </FileValue>
        </Label>
      </InputContainer>
    </div>
  );
};

export default ImageInput;

const InputContainer = styled.div`
  display: flex;
  align-items: center;

  background-color: #151f30;
  border: 1px solid transparent;
  border-radius: 16px;
  height: 44px;
  position: absolute;
  color: #fff;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
  & label {
    margin-bottom: 0;
    width: 100%;
    cursor: pointer;
    padding: 7px 20px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const UploadIconWrapper = styled.span`
  display: flex;
  align-items: center;

  flex: auto;
  height: 44px;
  top: 0;
  width: 40px;
  margin-right: 10px;

  & svg {
    fill: #2f80ed;
  }
`;

const FileValue = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  box-sizing: border-box;

  overflow: hidden;
  //   float: left;
  white-space: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2f80ed;
    outline: 1px solid #151f30;

    border-radius: 2px;
    cursor: pointer;

    border-left: 16px transparent solid;
    border-right: 16px transparent solid;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background: #151f30;
    border-left: 16px transparent solid;
    border-right: 16px transparent solid;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
