import React from "react";
import { SearchIcon } from "../../../../images";

type Props = {
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
};
const Search = ({ onChange }: Props) => {
  return (
    <>
      <form action="#" className="main__title-form">
        <input
          type="text"
          placeholder="Find movie / tv series.."
          onChange={onChange}
        />
        <button type="button">
          <SearchIcon />
        </button>
      </form>
    </>
  );
};

export default Search;
