import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import "./Auth.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FacebookIcon, GoogleIcon, LogoImage, TwitterIcon } from "../../images";
import BackgoundImage from "../../images/jpg/bg.jpg";
import { AuthFormData, Errors } from "../type";
import { signIn, signUp } from "../../store/actions/auth/auth.actions";
import { authFormSignUpValidate, authFormSignInValidate } from "./utils";
import ErrorAlertWrapper from "../common/Form/ErrorAlert/ErrorAlertWrapper";
import ErrorAlert from "../common/Form/ErrorAlert/ErrorAlert";
import { RootState } from "../../store/reducers";
import { InProgress } from "../common/Loaders/InProgress";
import { Done } from "../common/Loaders/Done";

const defaultAuthFormData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  remember: false,
  privacyPolicyAgreed: false,
};

const Auth = () => {
  //
  const [isSignIn, setIsSignIn] = useState(true);
  const [authFormData, setAuthFormData] = useState<AuthFormData>(
    defaultAuthFormData
  );
  const [formErrors, setFormErrors] = useState<Errors>([]);
  // I'm using the Error Trigger Count to Allow the Validation Error Popup to be shown again when triggered even if it has been triggered before
  const [errorTriggerCount, setErrorTriggerCount] = useState(0);
  const authStatus = useSelector((state: RootState) => state.auth.auth_status);
  const authError = useSelector((state: RootState) => state.auth.error_message);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authStatus === "FAILED") {
      setFormErrors([{ message: String(authError?.message), show: true }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setAuthFormData({ ...authFormData, [e.target.name]: e.target.checked });
    } else {
      setAuthFormData({ ...authFormData, [e.target.name]: e.target.value });
    }
    // console.log(authFormData);
  };

  // SWITCH FROM SIGN UP TO SIGN IN BY INVERTING VALUE OF ISSIGNIN
  const switchMode = () => {
    setIsSignIn((prevIsSignIn) => !prevIsSignIn);
  };

  // TODO
  const handleForgotPassword = () => {};
  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();

    var formData = new FormData();

    // If Signing In
    if (isSignIn) {
      let err: Errors = authFormSignInValidate(authFormData);

      if (err.length > 0) {
        setErrorTriggerCount(errorTriggerCount + 1);
        setFormErrors([...err]);
        return;
      } else {
        setFormErrors([]);
        formData.append("email", authFormData.email);
        formData.append("password", authFormData.password);
        // TODO: HANDLE REMEMBER ME
        dispatch(signIn(formData, history));
      }
    }
    // if signing up
    else {
      let err: Errors = authFormSignUpValidate(authFormData);

      if (err.length > 0) {
        setErrorTriggerCount(errorTriggerCount + 1);
        setFormErrors([...err]);
        return;
      } else {
        setFormErrors([]);
        formData.append("firstName", authFormData.firstName);
        formData.append("lastName", authFormData.lastName);
        formData.append("email", authFormData.email);
        formData.append("password", authFormData.password);
        formData.append("confirmPassword", authFormData.confirmPassword);

        dispatch(signUp(formData, history));
      }
    }
  };

  const handleDemoSubmit = (signInDetails: AuthFormData) => {
    var formData = new FormData();

    // If Signing In
    if (isSignIn) {
      let err: Errors = authFormSignInValidate(signInDetails);

      if (err.length > 0) {
        setErrorTriggerCount(errorTriggerCount + 1);
        setFormErrors([...err]);
        return;
      } else {
        setFormErrors([]);
        formData.append("email", signInDetails.email);
        formData.append("password", signInDetails.password);
        // TODO: HANDLE REMEMBER ME
        dispatch(signIn(formData, history));
      }
    }
  };

  // TODO: IMPLEMENT ONLY IN CUSTOMER SIDE NOT ADMIN
  // const handleFacebookSignIn = () => {};
  // const handleTwitterSignIn = () => {};
  // const handleGoogleSignIn = () => {};

  return (
    <>
      <Helmet>
        <title>Sign in - Watch TV</title>
        <script type="text/javascript" src="/assets/js/admin.js"></script>
      </Helmet>

      <div className="sign section--bg" data-bg={BackgoundImage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sign__content">
                {/* Select which form to display */}

                <>
                  <form className="sign__form">
                    <Link to="/" className="sign__logo">
                      <img src={LogoImage} alt="" />
                    </Link>
                    <ErrorAlertWrapper>
                      {formErrors.map((error, index) => (
                        <ErrorAlert
                          key={`${index} ${errorTriggerCount}`}
                          index={index}
                          errors={formErrors}
                          setErrors={(errors) => setFormErrors([...errors])}
                        />
                      ))}
                    </ErrorAlertWrapper>
                    {!isSignIn && (
                      <div className="sign__group">
                        <div className="sign__input__group">
                          <input
                            name="firstName"
                            value={authFormData.firstName}
                            onChange={handleChange}
                            type="text"
                            className="sign__input"
                            placeholder="First Name"
                            required
                          />

                          <input
                            name="lastName"
                            value={authFormData.lastName}
                            onChange={handleChange}
                            type="text"
                            className="sign__input"
                            placeholder="Last Name"
                            required
                          />
                        </div>
                      </div>
                    )}

                    <div className="sign__group">
                      <input
                        name="email"
                        value={authFormData.email}
                        onChange={handleChange}
                        type="text"
                        className="sign__input"
                        placeholder="Email"
                        required
                      />
                    </div>

                    <div className="sign__group">
                      <input
                        name="password"
                        value={authFormData.password}
                        onChange={handleChange}
                        type="password"
                        className="sign__input"
                        placeholder="Password"
                        required
                      />
                    </div>

                    {!isSignIn && (
                      <div className="sign__group">
                        <input
                          name="confirmPassword"
                          value={authFormData.confirmPassword}
                          onChange={handleChange}
                          type="password"
                          className="sign__input"
                          placeholder="Confirm Password"
                          required
                        />
                      </div>
                    )}

                    <div className="sign__group sign__group--checkbox">
                      {isSignIn ? (
                        <>
                          <input
                            id="remember"
                            name="remember"
                            type="checkbox"
                            defaultChecked={false}
                            onChange={handleChange}
                          />
                          <label htmlFor="remember">Remember me</label>
                        </>
                      ) : (
                        <>
                          <input
                            id="privacyPolicyAgreed"
                            name="privacyPolicyAgreed"
                            type="checkbox"
                            defaultChecked={false}
                            onChange={handleChange}
                          />
                          <label htmlFor="privacyPolicyAgreed">
                            I agree to the{" "}
                            <Link to="/PrivacyPolicy">Privacy policy</Link>
                          </label>
                        </>
                      )}
                    </div>

                    <button
                      className="sign__btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {isSignIn ? "Sign in" : "Sign Up"}
                      {authStatus === "IN PROGRESS" && <InProgress size={2} />}
                      {authStatus === "DONE" && <Done size={2} />}
                    </button>

                    {/* <span className="sign__delimiter">or</span> */}

                    {/* <div className="sign__social">
                      <span className="fb" onClick={handleFacebookSignIn}>
                        <FacebookIcon />
                      </span>
                      <span className="tw" onClick={handleTwitterSignIn}>
                      <TwitterIcon />
                      </span>
                      <span className="gl" onClick={handleGoogleSignIn}>
                        <GoogleIcon />
                      </span>
                    </div> */}

                    <span className="sign__text">
                      {isSignIn
                        ? "Don't have an account? "
                        : "Already have an account? "}
                      <span className="link" onClick={switchMode}>
                        {isSignIn ? "Sign up!" : "Sign in!"}
                      </span>
                    </span>

                    {isSignIn && (
                      <span className="sign__text">
                        <span className="link" onClick={handleForgotPassword}>
                          Forgot password?
                        </span>
                      </span>
                    )}
                    <button
                      className="sign__btn demo__sign__btn"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isSignIn) setIsSignIn(true);
                        const signInDetails = {
                          email: "demoadmin@jesuseyitan.com",
                          password: "demoadmin123",
                          remember: true,
                        };
                        setAuthFormData({
                          ...authFormData,
                          ...signInDetails,
                        });
                        handleDemoSubmit({ ...authFormData, ...signInDetails });
                      }}
                    >
                      {"DEMO Sign In"}
                    </button>
                  </form>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
