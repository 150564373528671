import React, { FC } from "react";
import { Link } from "react-router-dom";
import { LogoImage } from "../../../images";

type Props = {
  to: string;
  styleClass: string;
};
const Logo: FC<Props> = ({ to, styleClass }) => {
  return (
    <>
      {/* logo  */}
      <Link to={to} className={styleClass}>
        <img src={LogoImage} alt="" />
      </Link>
      {/* end  logo  */}
    </>
  );
};

export default Logo;
