import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./Dashbox.css";
import { RefreshIcon } from "../../../images";
import DashboxTable from "./DashboxTable/DashboxTable";
import {
  StoreStateKey,
  StateObjectKey,
  DashboxTableHeadData,
} from "../../type";

type Props = {
  dashboxTitle: string;
  DashboxSvgIcon: () => JSX.Element;
  stateName: StoreStateKey;
  stateObject: StateObjectKey;
  dashboxTableHeadData: DashboxTableHeadData;
  handleRefresh: (() => void) | undefined;
  handleViewAll: (() => void) | undefined;
};
const Dashbox: FC<Props> = ({
  dashboxTitle,
  DashboxSvgIcon,
  stateName,
  stateObject,
  dashboxTableHeadData,
  handleRefresh,
  handleViewAll,
}) => {
  return (
    <>
      {/*  Dashbox */}
      <div className="col-12 col-xl-6">
        <div className="dashbox">
          <div className="dashbox__title">
            <h3>
              <DashboxSvgIcon />
              {dashboxTitle}
            </h3>

            <div className="dashbox__wrap">
              <span className="dashbox__refresh" onClick={handleRefresh}>
                <RefreshIcon />
              </span>
              <span className="dashbox__more" onClick={handleViewAll}>
                View All
              </span>
            </div>
          </div>

          {/* Table Data */}
          <DashboxTable
            stateName={stateName}
            tableHeadData={dashboxTableHeadData}
            stateObject={stateObject}
          />
        </div>
      </div>
    </>
  );
};

export default Dashbox;
