import { History, LocationState } from "history";
import * as api from "../../../api";
import {
  AUTH_SIGN_IN,
  AUTH_SIGN_OUT,
  AUTH_ERROR,
  AUTH_STATUS,
} from "../../actions/auth/actionTypes";
import { AuthDispatchType } from "../../reducers/auth/type";

export const checkToken = () => {
  return api
    .checkToken()
    .then((res) => {
      if (res.status === 200) return true;
      return false;
    })
    .catch(() => {
      return false;
    });
};

//#region ::: SIGN IN ACTION
export const signIn = (
  formData: FormData,
  history: History<LocationState>
) => async (dispatch: AuthDispatchType) => {
  dispatch({ type: AUTH_STATUS, payload: "IN PROGRESS" });
  try {
    const { data } = await api.signIn(formData);

    dispatch({ type: AUTH_SIGN_IN, payload: data });
    dispatch({ type: AUTH_STATUS, payload: "DONE" });

    history.push("/Dashboard");
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: {
        status_code: error.response.status,
        message: error.response.data.message,
      },
    });
    dispatch({ type: AUTH_STATUS, payload: "FAILED" });
  }
};
//#endregion

//#region ::: SIGN UP ACTION
export const signUp = (
  formData: FormData,
  history: History<LocationState>
) => async (dispatch: AuthDispatchType) => {
  dispatch({ type: AUTH_STATUS, payload: "IN PROGRESS" });
  try {
    //  sign up the user
    const { data } = await api.signUp(formData);
    dispatch({ type: AUTH_SIGN_IN, payload: data });

    dispatch({ type: AUTH_STATUS, payload: "DONE" });
    history.push("/Dashboard");
  } catch (error) {
    // clg error:
    dispatch({
      type: AUTH_ERROR,
      payload: {
        status_code: error.response.status,
        message: error.response.data.message,
      },
    });
    dispatch({ type: AUTH_STATUS, payload: "FAILED" });
  }
};
//#endregion

export const signOut = (history: History<LocationState>) => (
  dispatch: AuthDispatchType
) => {
  dispatch({ type: AUTH_SIGN_OUT });
  dispatch({ type: AUTH_STATUS, payload: "IDLE" });
  history.push("/");
};
