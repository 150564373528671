import React, { FC, useEffect, useState } from "react";
import moment from "moment";
// SVG Icons
import {
  ArrowBackLineIcon,
  ArrowForwardLineIcon,
  BanIcon,
  DeleteIcon,
  EditIcon,
  StarIcon,
  ViewIcon,
} from "../../../../images";
// PROPS TYPE IMPORT
import {
  HandleTableAction,
  TableActions,
  TableData,
  TableHeadData,
} from "../../../type";
import styled from "styled-components";

const ItemsPerPage = 10;
const getPagesNumber = (num: number) => {
  return Math.ceil(num / ItemsPerPage);
};
const getPagesArray = (from: number, to: number, step: number = 1) => {
  let i = from;
  const range = [];
  while (i <= to) {
    range.push(i);
    i += step;
  }
  return range;
};
type Props = {
  tableData: TableData;
  tableHeadData: TableHeadData;
  actions: TableActions;
  handleAction: HandleTableAction;
};
const MainTable: FC<Props> = ({
  tableData,
  tableHeadData,
  actions,
  handleAction,
}) => {
  const [totalPages, setTotalPages] = useState(
    getPagesNumber(tableData.length)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState<number[]>(
    getPagesArray(1, totalPages)
  );

  useEffect(() => {
    setTotalPages(getPagesNumber(tableData.length));
  }, [tableData]);
  useEffect(() => {
    setPageArray(getPagesArray(1, totalPages));
  }, [totalPages]);

  return (
    <>
      <div className="col-12">
        <div className="main__table-wrap">
          <table className="main__table">
            <thead>
              <tr>
                <th>ID</th>
                {tableHeadData.map((data) => (
                  <th key={data.key}>{data.name.toUpperCase()}</th>
                ))}
                <th>ACTIONS</th>
              </tr>
            </thead>

            <tbody>
              {tableData
                .slice(
                  (currentPage - 1) * ItemsPerPage,
                  currentPage * ItemsPerPage
                )
                .map((rowData, index) => (
                  <tr key={rowData._id}>
                    <td>
                      <div className="main__table-text">
                        {(currentPage - 1) * ItemsPerPage + (index + 1)}
                      </div>
                    </td>
                    {tableHeadData.map((data) => (
                      <td key={data.key}>
                        <div
                          className={`main__table-text  ${
                            data.key === "status"
                              ? "main__table-text--" +
                                (rowData[data.key] === "Visible"
                                  ? "green"
                                  : "red")
                              : ""
                          }`}
                        >
                          {data.key === "rating" && <StarIcon />}

                          {data.key === "createdAt"
                            ? moment(rowData[data.key]).format("DD MMM YYYY")
                            : rowData[data.key]}
                        </div>
                      </td>
                    ))}
                    <td>
                      <div className="main__table-btns">
                        {actions.map((action) => (
                          <div
                            key={action}
                            onClick={() => handleAction(action, rowData._id)}
                            className={`main__table-btn main__table-btn--${
                              action === "change_status" ? "banned" : action
                            } open-modal`}
                          >
                            {action === "change_status" && <BanIcon />}
                            {action === "view" && <ViewIcon />}
                            {action === "edit" && <EditIcon />}
                            {action === "delete" && <DeleteIcon />}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      {
        tableData.length > 10 && (
          // <!-- paginator -->
          <div className="col-12">
            <Paginator className="paginator">
              <span className="paginator__pages">
                {ItemsPerPage} from {tableData.length}{" "}
              </span>

              <ul className="paginator__paginator">
                <li>
                  <span>
                    <ArrowBackLineIcon />
                    {/*  */}
                  </span>
                </li>

                {pageArray.map((value) => (
                  <li
                    key={value}
                    className={`${value === currentPage && "active"}`}
                    onClick={() => setCurrentPage(value)}
                  >
                    <span>{value}</span>
                  </li>
                ))}

                <li>
                  <span>
                    <ArrowForwardLineIcon />
                  </span>
                </li>
              </ul>
            </Paginator>
          </div>
        )
        // <!-- end paginator -->
      }
    </>
  );
};

export default MainTable;

const Paginator = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px auto 30px;
  width: 100%;
  height: 50px;
  border-radius: 16px;
  background-color: #151f30;

  & .paginator__pages {
    display: none;
  }

  & .paginator__paginator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & li {
      cursor: pointer;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      &.active span {
        color: #2f80ed;
        cursor: default;
      }

      & span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 12px;
        background-color: #131720;
        font-size: 14px;
        color: #e0e0e0;
        font-weight: 500;

        transition: color 300ms;
        & svg {
          stroke: #e0e0e0;
          transition: 0.5s;
        }
        &:hover {
          color: #2f80ed;
        }
        &:hover svg {
          stroke: #2f80ed;
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding: 0 20px;
    height: 60px;
    justify-content: space-between;

    & .paginator__pages {
      display: block;
      font-size: 14px;
      color: #e0e0e0;
      font-weight: 500;
    }
  }

  @media (min-width: 1200px) {
    margin: 20px auto 0;
  }
`;
