import jwtDecode from "jwt-decode";
import { AUTH_SET_USER, AUTH_SIGN_OUT } from "./store/actions/auth/actionTypes";
import { AuthDispatchType } from "./store/reducers/auth/type";

export const IsTokenValid = (dispatch: AuthDispatchType) => {
  const user = JSON.parse(localStorage.getItem("WatchTV_Admin") as string);
  if (user?.token) {
    const decodedToken = jwtDecode(user.token) as DecodedToken;
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      dispatch({ type: AUTH_SIGN_OUT });
      return false;
    } else {
      dispatch({ type: AUTH_SET_USER, payload: user });
      return true;
    }
  } else {
    return false;
  }
};
type DecodedToken = {
  email: string;
  id: string;
  exp: any;
};
