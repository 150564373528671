import React, { useEffect, useState } from "react";
import "./Movies.css";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import {
  HandleTableAction,
  MovieSortDataKey,
  TableHeadData,
  TableSortData,
} from "../type";
// TODO ::: Redux Store
import { RootState } from "../../store/reducers";
// Components
import Main from "../common/Main/Main";
import FilterSort from "../common/Main/FilterSort/FilterSort";
import MainTable from "../common/Main/MainTable/MainTable";
import Search from "../common/Main/Search/Search";
import { sortMovies } from "../../store/actions/movies/movies.actions";

// TODO
// Make into constant
const tableHeadData: TableHeadData = [
  { key: "title", name: "Title" },
  { key: "rating", name: "Rating" },
  { key: "category", name: "Category" },
  { key: "views", name: "Views" },
  { key: "status", name: "Status" },
  { key: "createdAt", name: "Created Date" },
];

const movieSortData: TableSortData = [
  { key: "createdAt", name: "Date created" },
  { key: "release_date", name: "Date released" },
  { key: "rating", name: "Rating" },
  { key: "views", name: "Views" },
  { key: "title", name: "Title" },
];

const Movies = () => {
  const movies = useSelector((state: RootState) => state.movies.movies);
  const currentSort = useSelector(
    (state: RootState) => state.movies.sort_option
  );
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchResults, setSearchResults] = useState<typeof movies>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSearching && searchTerm) {
      handleSearch(searchTerm);
    }
  }, [currentSort]);

  // Todo: Handle Action called in Table
  const handleAction: HandleTableAction = (action, id) => {
    console.log("handle Action called");
  };

  // Function that handles the sorting method selected from the dropdown in Filtersort component
  const handleSort = (option: MovieSortDataKey) => {
    dispatch(sortMovies({ option, asc: true }));
  };

  const handleSearch = (search_term: string) => {
    setSearchTerm(search_term);
    if (search_term !== "") {
      setIsSearching(true);
      let results = movies.filter((movie) =>
        movie.title.toLowerCase().includes(search_term)
      );
      setSearchResults(results);
    } else {
      setIsSearching(false);
    }
    console.log(isSearching);
  };

  return (
    <>
      <Helmet>
        <title>All Movies - Watch TV</title>

        {/* Reloading Script Tag to recall functions on load */}
        <script src="assets/js/admin.js"></script>
      </Helmet>
      <Main>
        {/* Main Title */}
        <div className="col-12">
          <div className="main__title">
            <h2>Movies</h2>

            <span className="main__title-stat">{movies.length} total</span>

            <div className="main__title-wrap">
              {/* Filter Sort */}
              <FilterSort
                currentSort={currentSort}
                sortData={movieSortData}
                handleSort={handleSort}
              />

              {/* Search */}
              <Search
                onChange={(e) => handleSearch(e.target.value.toLowerCase())}
              />
            </div>
          </div>

          <MainTable
            tableData={isSearching ? searchResults : movies}
            tableHeadData={tableHeadData}
            actions={["change_status", "view", "edit", "delete"]}
            handleAction={handleAction}
          />
        </div>
      </Main>
    </>
  );
};

export default Movies;
