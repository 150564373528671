import React, { FC } from "react";
import "./Stats.css";

type Props = {
  statsTitle: string;
  statsCount: number;
  StatsSvgIcon: () => JSX.Element;
};

const Stats: FC<Props> = ({ statsTitle, statsCount, StatsSvgIcon }) => {
  return (
    <>
      {/* Stats */}
      <div className="col-12 col-sm-6 col-xl-3">
        <div className="stats">
          <span>{statsTitle}</span>
          <p>{statsCount}</p>
          <StatsSvgIcon />
        </div>
      </div>
    </>
  );
};

export default Stats;
