import React, { FC } from "react";
import { useSelector } from "react-redux";
import { StarIcon } from "../../../../images";
import { RootState } from "../../../../store/reducers";

import {
  DashboxTableDataType,
  DashboxTableHeadData,
  StateObjectKey,
  StoreStateKey,
} from "../../../type";

type Props = {
  stateName: StoreStateKey;
  stateObject: StateObjectKey;
  tableHeadData: DashboxTableHeadData;
};

const DashboxTable: FC<Props> = ({ stateName, stateObject, tableHeadData }) => {
  const table_data: DashboxTableDataType = useSelector(
    (state: RootState) => state[stateName][stateObject]
  );

  // console.table(table_data);

  // todo ::: Set Unique Key for table
  return (
    <div className="dashbox__table-wrap">
      <table className="main__table main__table--dash">
        <thead>
          <tr>
            {tableHeadData.map(({ key, name }) => (
              <th key={key}>{name.toUpperCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* TODO: FIX ANY TYPE PASSED AS ROWDATA */}
          {table_data.map((rowData: any, index) => (
            <tr key={Math.random() * index}>
              {tableHeadData.map(({ key, name }) => (
                <DashboxTableCellData
                  key={key}
                  data={key === ("_id" as string) ? index + 1 : rowData[key]}
                  rateIcon={key === "rating"}
                  // link={key === "title"}
                  status={key === "status"}
                  // to={`/dashboard/${rowData._id}`}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// TODO SET TYPE OF PROPS
type CellDataProps = {
  data: string;
  status: boolean;
  rateIcon: boolean;
  // link?: boolean;
  // to?: string;
};
const DashboxTableCellData: FC<CellDataProps> = ({
  data,
  rateIcon = false,
  // link = false,
  // to,
  status = false,
}) => {
  return (
    <>
      <td>
        <div
          className={`main__table-text ${
            rateIcon ? "main__table-text--rate" : ""
          } ${
            status
              ? data === "Visible"
                ? "main__table-text--green"
                : "main__table-text--grey"
              : ""
          }`}
        >
          {rateIcon ? <StarIcon /> : ""}
          {/* {link ? <Link to={to}>{data}</Link> : data} */}
          {data}
        </div>
      </td>
    </>
  );
};

export default DashboxTable;
