import React, { FC } from "react";
import styled from "styled-components";

const ErrorAlertWrapper: FC = ({ children }) => {
  return (
    <Wrapper className="col-12">
      <Container>{children}</Container>
    </Wrapper>
  );
};

export default ErrorAlertWrapper;

const Wrapper = styled.div`
  @media (max-width: 770px) {
    position: sticky;
    z-index: 1052;
    top: 5em;
    width: 95%;
  }
`;

const Container = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  /* margin-left: auto; */
  padding-right: 1em;
  min-width: 300px;
  /* width: 30%; */
  position: absolute;
  z-index: 1052;
`;
