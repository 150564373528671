import React, { FC } from "react";
import "./Form.css";

type Props = {
  children: React.ReactNode;
};
const Form: FC<Props> = ({ children }) => {
  return (
    <div className="col-12">
      <form className="form">
        <div className="row">{children}</div>
      </form>
    </div>
  );
};

export default Form;
