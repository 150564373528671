import React, { FC, ReactNode, useState } from "react";
import "./Main.css";
// Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

type Props = {
  children: ReactNode;
};
const Main: FC<Props> = ({ children }) => {
  const [toggleSidebarOnSmallScreen, setToggleSidebarOnSmallScreen] = useState(
    /*<boolean>*/ false
  );
  return (
    <>
      {/* Header */}
      <Header
        showSidebar={toggleSidebarOnSmallScreen}
        setShowSidebar={setToggleSidebarOnSmallScreen}
      />

      {/* Sidebar */}
      <Sidebar toggleSidebarOnSmallScreen={toggleSidebarOnSmallScreen} />

      {/* Dashboard */}
      <main className="main">
        <div className="container-fluid">
          <div className="row">{children}</div>
        </div>
      </main>
    </>
  );
};

export default Main;
