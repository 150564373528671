import styled from "styled-components";

export const Done = styled("div")<{ size: number }>`
  width: ${(props) => props.size || 2}em;
  height: ${(props) => props.size || 2}em;
  margin-left: 0.5em;
  margin-top: -${(props) => props.size / 4 || 0.5}em;
  height: 100%;
  transform: rotate(45deg);
  animation: poin 0.6s ease-in-out 0.3s;
  &:before {
    content: "";
    position: absolute;
    top: 69%;
    left: 22%;
    height: 10%;
    border-radius: 2em;
    background: #fff;
    transition: 0.5s;
    animation: short 0.3s ease-in forwards;
  }
  &:after {
    content: "";
    position: absolute;
    top: 80%;
    left: 52%;
    height: 10%;
    border-radius: 2em;
    background: #fff;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    transition: 0.5s;
    animation: long 0.3s ease-out 0.3s forwards;
  }
  @keyframes short {
    0% {
      width: 0;
    }
    100% {
      width: 45%;
    }
  }
  @keyframes long {
    0% {
      width: 0;
    }
    100% {
      width: 75%;
    }
  }
  @keyframes poin {
    0% {
      transform: rotate(45deg) scale(1);
    }
    50% {
      transform: rotate(50deg) scale(1.2);
    }
    100% {
      transform: rotate(45deg) scale(1);
    }
  }

  .form__btn:hover &:before,
  .form__btn:hover &:after {
    background: #2f80ed;
  }
`;
