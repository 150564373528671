import {
  ADD_MOVIE,
  ADD_MOVIE_STATUS,
  GET_MOVIES,
  SORT_MOVIES,
} from "../../actions/movies/actionTypes";

import {
  AddMovieStatus,
  IMovie,
  LatestMovie,
  MoviesActionTypes,
  MovieSortOption,
  MovieState,
  SortOption,
  TopMovie,
} from "./type";

const INITIAL_STATE: MovieState = {
  movies: [],
  add_movie_status: "IDLE",
  top_movies: [],
  latest_movies: [],
  sort_option: {
    option: "title",
    asc: true,
  },
};

const sortWithString = (option: MovieSortOption, asc: boolean) => {
  switch (option) {
    case "createdAt":
    case "release_date":
      return (a: IMovie, b: IMovie) =>
        +new Date(b[option]) - +new Date(a[option]);
    case "views":
    case "rating":
      return (a: IMovie, b: IMovie) => b.rating - a.rating;
    default:
      return (a: IMovie, b: IMovie) =>
        asc
          ? a[option] < b[option]
            ? -1
            : a[option] > b[option]
            ? 1
            : 0
          : a[option] > b[option]
          ? -1
          : a[option] < b[option]
          ? 1
          : 0;
  }
};

const reducer = (
  state: MovieState = INITIAL_STATE,
  action: MoviesActionTypes
): MovieState => {
  switch (action.type) {
    case SORT_MOVIES:
      let sort_option = action.payload as SortOption;
      let movies = state.movies.sort(
        sortWithString(sort_option.option, sort_option.asc)
      );
      return { ...state, sort_option, movies: [...movies] };
    case GET_MOVIES:
      const latest_movies = (action.payload as IMovie[])
        .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
        .slice(0, 5) as LatestMovie[];
      const top_movies = (action.payload as IMovie[])
        .sort((a, b) => b.rating - a.rating)
        .slice(0, 5) as TopMovie[];

      // TODO: HANDLE SORT OPTION BASED ON SELECTED DROPDOWN
      let default_movies;
      if (true) {
        default_movies = (action.payload as IMovie[]).sort(
          sortWithString("title", true)
        );
      }

      var new_addition = {
        // Top Movies - Dashboard
        top_movies: top_movies,
        // lates Movies - Dasboard
        latest_movies: latest_movies,
        // Movies
        movies: default_movies,
      };
      return {
        ...state,
        ...new_addition,
      };

    case ADD_MOVIE:
      return {
        ...state,
        movies: [...state.movies, action.payload] as IMovie[],
      };
    case ADD_MOVIE_STATUS:
      return { ...state, add_movie_status: action.payload as AddMovieStatus };
    default:
      return state;
  }
};

export default reducer;
