import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddMovie.css";
// Third Parties
import { Helmet } from "react-helmet-async";
import moment from "moment";
// Store
import { RootState } from "../../../store/reducers";
import { addMovie } from "../../../store/actions/movies/movies.actions";
import { ADD_MOVIE_STATUS } from "../../../store/actions/movies/actionTypes";
// todo: Temp Data : Make into CONSTANT later
import { genresData } from "../../../temp/genres.data";
// Components
import Main from "../../common/Main/Main";
import Form from "../../common/Form/Form";
import ImageUploadField from "./Fields/ImageUploadField";
import Select from "../../common/Form/Select/Select";
import Checkbox from "../../common/Form/Checkbox/Checkbox";
import MultiSelect from "../../common/Form/MultiSelect/MultiSelect";
import ImageInput from "../../common/Form/ImageInput/ImageInput";
import RadioInput from "../../common/Form/RadioInput/RadioInput";
import ErrorAlert from "../../common/Form/ErrorAlert/ErrorAlert";
import { InProgress } from "../../common/Loaders/InProgress";
import { Done } from "../../common/Loaders/Done";
// TYPE
import { MovieFormData } from "../../type";
import { Errors } from "../../type";
// UTILS
import { movieFormValidate } from "./utils";
import ErrorAlertWrapper from "../../common/Form/ErrorAlert/ErrorAlertWrapper";

// DEFAULT FORM DATA
const defaultFormData = {
  title: "",
  description: "",
  release_date: moment().format("YYYY-MM-DD"),
  running_time: null,
  resolution: "HD",
  adult: false,
  genres: [],
  tags: [],

  cover_image: null,
  backdrop_image: null,

  rating: null,
  status: "Hidden",
  category: "Movie",

  // TODO: CREATOR
};

const AddMovie: FC = () => {
  //#region ::: movieData
  const [movieData, setMovieData] = useState<MovieFormData>(defaultFormData);
  //#endregion
  const [formErrors, setFormErrors] = useState<Errors>([]);
  const [errorTriggerCount, setErrorTriggerCount] = useState(0); // I'm using the Error Trigger Count to Allow the Validation Error Popup to be shown again after it has been triggered before
  const addMovieStatus = useSelector(
    (state: RootState) => state.movies.add_movie_status
  );

  // TODO: REMOVE
  const movies = useSelector((state: RootState) => state.movies.movies);
  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch, movies]);

  useEffect(() => {
    // console.log("count");
    if (addMovieStatus === "DONE") {
      clear();
      setTimeout(() => {
        dispatch({ type: ADD_MOVIE_STATUS, payload: "IDLE" });
      }, 2000);
    }
  }, [addMovieStatus, dispatch]);

  // useEffect(() => {
  //   console.log(movieData);
  // }, [movieData]);
  // console.log("Form Errors >>> ", formErrors);

  const clear = () => {
    setFormErrors([]);
    setMovieData(defaultFormData);
  };

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // Form Validation
    event.preventDefault();
    let err = movieFormValidate(movieData);

    if (err.length > 0) {
      setErrorTriggerCount(errorTriggerCount + 1);
      setFormErrors([...err]);
      return;
    } else {
      let formData = new FormData();

      Object.entries(movieData).map(([key, value]) =>
        formData.append(key, value as string | Blob)
      );

      // for (var pair of formData.entries()) {
      //   console.log(pair[0], pair[1]);
      // }
      dispatch(addMovie(formData));
      // clear();
    }
  };

  return (
    <>
      <Helmet>
        <title>Add Movie - Watch TV</title>
        <script type="text/javascript" src="/assets/js/admin.js"></script>
      </Helmet>
      <Main>
        {/* Page Title */}
        <div className="col-12">
          <div className="main__title">
            <h2>Add New Movie</h2>
          </div>
        </div>

        {/* Add Movie Form */}
        <Form>
          {/* Error Validation */}
          <ErrorAlertWrapper>
            {formErrors.map((error, index) => (
              <ErrorAlert
                key={`${index} ${errorTriggerCount}`}
                index={index}
                errors={formErrors}
                setErrors={(errors) => setFormErrors([...errors])}
              />
            ))}
          </ErrorAlertWrapper>

          <div className="col-12 col-md-5 form__cover">
            <div className="row">
              {/* Cover Image Field */}
              <ImageUploadField
                image={movieData.cover_image}
                setImage={(file) =>
                  setMovieData({ ...movieData, cover_image: file })
                }
              />
            </div>
          </div>

          <div className="col-12 col-md-7 form__content">
            <div className="row">
              {/* Title Field */}
              <div className="col-12">
                <div className="form__group">
                  <input
                    type="text"
                    className="form__input"
                    placeholder="Title"
                    value={movieData.title}
                    onChange={(e) =>
                      setMovieData({
                        ...movieData,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              {/* Description Field */}
              <div className="col-12">
                <div className="form__group">
                  <textarea
                    id="text"
                    name="text"
                    className="form__textarea"
                    placeholder="Description"
                    value={movieData.description}
                    onChange={(e) =>
                      setMovieData({
                        ...movieData,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              {/* Release Date Field */}
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="form__group">
                  <input
                    type="date"
                    className="form__input"
                    placeholder="Release Date"
                    value={movieData.release_date as string}
                    onChange={(e) =>
                      setMovieData({
                        ...movieData,
                        release_date: moment(new Date(e.target.value)).format(
                          "YYYY-MM-DD"
                        ),
                      })
                    }
                  />
                </div>
              </div>

              {/* Running Time Field */}
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="form__group">
                  <input
                    type="number"
                    className="form__input"
                    placeholder="Running Time (mins)"
                    value={
                      movieData.running_time === null ||
                      isNaN(movieData.running_time)
                        ? ""
                        : (movieData.running_time as number)
                    }
                    onChange={(e) =>
                      setMovieData({
                        ...movieData,
                        running_time: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
              </div>

              {/* Resolution Field */}
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="form__group">
                  <Select
                    options={["FullHD", "HD"]}
                    value={movieData.resolution}
                    value_name="Resolution"
                    onChange={(value) =>
                      setMovieData({
                        ...movieData,
                        resolution: value,
                      })
                    }
                  />
                </div>
              </div>

              {/* Adult Field */}
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="form__group">
                  <Checkbox
                    value={movieData.adult}
                    description="Adult"
                    onChange={(value) =>
                      setMovieData({
                        ...movieData,
                        adult: value,
                      })
                    }
                  />
                </div>
              </div>

              {/* Genres Field */}
              <div className="col-12 col-lg-6">
                <div className="form__group">
                  <MultiSelect
                    options={genresData}
                    values={movieData.genres}
                    placeholder="Choose genre/genres"
                    value_name="Genres"
                    onChange={(values) =>
                      setMovieData({
                        ...movieData,
                        genres: values,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tags Field */}
              <div className="col-12 col-lg-6">
                <div className="form__group">
                  <input
                    type="text"
                    className="form__input"
                    placeholder="Tags"
                    value={movieData.tags}
                    onChange={(e) =>
                      setMovieData({
                        ...movieData,
                        tags: e.target.value
                          .split(",")
                          .map((tag) => tag.trim()),
                      })
                    }
                  />
                </div>
              </div>

              {/* Backdrop Image Field */}
              <div className="col-12 col-lg-6">
                <div className="form__group">
                  <ImageInput
                    placeholder={"Upload Backdrop image"}
                    value={movieData.backdrop_image}
                    setImage={(file) =>
                      setMovieData({
                        ...movieData,
                        backdrop_image: file,
                      })
                    }
                  />
                </div>
              </div>

              {/* Rating Field */}
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="form__group">
                  <input
                    type="number"
                    min="1"
                    max="10"
                    className="form__input"
                    placeholder="Rating (1-10)"
                    value={
                      movieData.rating === null || isNaN(movieData.rating)
                        ? ""
                        : movieData.rating.toString()
                    }
                    onChange={(e) => {
                      setMovieData({
                        ...movieData,
                        rating: parseFloat(e.target.value),
                      });
                    }}
                  />
                </div>
              </div>

              {/* Status Field */}
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="form__group">
                  <Select
                    options={["Visible", "Hidden"]}
                    value={movieData.status}
                    value_name="Status"
                    onChange={(value) =>
                      setMovieData({
                        ...movieData,
                        status: value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Category Field */}
          <div className="col-12">
            <ul className="form__radio">
              <li>
                <span>Category type:</span>
              </li>
              <RadioInput
                options={["Movie", "TV Show"]}
                onChange={(e) =>
                  setMovieData({
                    ...movieData,
                    category: (e.target as HTMLInputElement).value,
                  })
                }
                value={movieData.category}
              />
            </ul>
          </div>

          {/* Submit Button */}
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <button
                  type="submit"
                  className="form__btn"
                  onClick={handleSubmit}
                >
                  Add Movie
                  {/* <InProgress size={2} />
                  <Done size={2} /> */}
                  {addMovieStatus === "IN PROGRESS" && <InProgress size={2} />}
                  {addMovieStatus === "DONE" && <Done size={2} />}
                </button>
                {/* {addMovieStatus === "DONE" ? "DONE" : addMovieStatus} */}
              </div>
            </div>
          </div>

          {/*  */}
        </Form>
      </Main>
    </>
  );
};

export default AddMovie;
