import React, { Dispatch, FC, SetStateAction } from "react";
import "./Header.css";
// Component
import Logo from "../Logo/Logo";

type Props = {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
};
const Header: FC<Props> = ({ showSidebar, setShowSidebar }) => {
  return (
    <>
      {/* header  */}
      <header className={`header ${showSidebar ? "header--active" : ""}`}>
        <div className="header__content">
          {/* header logo  */}
          <Logo to="/Dashboard" styleClass="header__logo" />
          {/* end header logo  */}

          {/* header menu btn */}
          <button
            className={`header__btn ${
              showSidebar ? "header__btn--active" : ""
            }`}
            onClick={() => setShowSidebar(!showSidebar)}
            type="button"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          {/* end header menu btn */}
        </div>
      </header>
      {/* end header */}
    </>
  );
};

export default Header;
