import React, { FC, useEffect, useState } from "react";
import { Errors } from "../../../type";
import "./ErrorAlert.css";

type Props = {
  index: number;
  errors: Errors;
  setErrors: (errors: Errors) => void;
};
const ErrorAlert: FC<Props> = ({ index, errors, setErrors }) => {
  const [hidden, setHidden] = useState(!errors[index].show);

  useEffect(() => {
    setTimeout(() => setHidden(!errors[index].show), 300);
  }, [errors, index]);

  const handleClose = () => {
    let tempErrors = errors;
    tempErrors[index].show = false;
    setErrors(tempErrors);
    // setTimeout(() => setHidden(true), 300);
  };

  useEffect(() => {
    if (!hidden) {
      setTimeout(() => handleClose(), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={`alert__message ${!errors[index].show ? "close" : ""} ${
          hidden ? "hide" : ""
        }`}
      >
        <span>Error: {errors[index].message} </span>
        <span className="alert__remove" onClick={handleClose}>
          ×
        </span>
      </div>
    </>
  );
};

export default ErrorAlert;
