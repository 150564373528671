import axios from "axios";

// SETUP
const BASE_URL = "https://watch-tv-project.herokuapp.com/admin";
// const BASE_URL = "http://localhost:5000/admin";
const API = axios.create({ baseURL: BASE_URL });

// Interceptor to send data that will be used by middleware
API.interceptors.request.use((req) => {
  if (localStorage.getItem("WatchTV_Admin")) {
    req.headers.authorization = `Bearer ${
      JSON.parse(String(localStorage.getItem("WatchTV_Admin"))).token
    }`;
  }
  return req;
});

// MOVIES
//#region
export const getMovies = () => API.get("/movies");
export const addMovie = (movie_formdata: FormData) =>
  API.post("/movies", movie_formdata, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
//#endregion

// ADMIN AUTH
//#region
export const signUp = (auth_formdata: FormData) =>
  API.post(`/authentication/signup`, auth_formdata);
export const signIn = (auth_formdata: FormData) =>
  API.post(`/authentication/signin`, auth_formdata);
export const checkToken = () => API.head(`/authentication/checkToken`);
//#endregion
