import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LogoutIcon, UserImage } from "../../../../images";
import { signOut } from "../../../../store/actions/auth/auth.actions";

const SidebarUser: FC = () => {
  const [adminUser, setAdminUser] = useState(
    JSON.parse(localStorage.getItem("WatchTV_Admin") as string)?.user
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSignout = () => {
    dispatch(signOut(history));
  };

  return (
    <>
      {/* sidebar user  */}
      <div className="sidebar__user">
        <div className="sidebar__user-img">
          {/* TODO ::: Authenticated User Profile Image */}
          <img src={UserImage} alt="AdminUser" />
        </div>

        <div className="sidebar__user-title">
          <span>Admin</span>
          {/* TODO ::: Authenticated user name  */}
          <p>
            {`${adminUser?.name.first_name} ${adminUser?.name.last_name[0]}.`}
          </p>
        </div>

        {/* TODO ::: CLICK TO LOGOUT */}
        <button
          className="sidebar__user-btn"
          type="button"
          onClick={handleSignout}
        >
          <LogoutIcon />
        </button>
      </div>
    </>
  );
};

export default SidebarUser;
