import React, { FC } from "react";
import "./Checkbox.css";

type Props = {
  value: boolean;
  description: string;
  onChange: (value: boolean) => void;
};

const Checkbox: FC<Props> = ({ value, description, onChange }) => {
  const handleToggle = () => {
    onChange(!value);
  };

  return (
    <div className="checkbox__parent">
      <label className="checkbox__container">
        <input type="checkbox" defaultChecked={value} onChange={handleToggle} />
        <span className="x" />
      </label>
      <span className="checkbox__text">{description}</span>
    </div>
  );
};

export default Checkbox;
