import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// Component
import Main from "../common/Main/Main";
import Stats from "./Stats/Stats";
import Dashbox from "./Dashbox/Dashbox";
// images
import {
  CatalogIcon,
  ItemsAddedIcon,
  NewCommentsIcon,
  RankIcon,
  RatingsIcon,
  UniqueViewsIcon,
} from "../../images";
import { DashboxTableHeadData } from "../type";
import { useDispatch } from "react-redux";
import {
  getMovies,
  sortMovies,
} from "../../store/actions/movies/movies.actions";

//  Set type when converted to txs
const dashboxTopMoviesHeadData: DashboxTableHeadData = [
  { key: "_id", name: "ID" },
  { key: "title", name: "Title" },
  { key: "category", name: "Category" },
  { key: "rating", name: "Rating" },
];
const dashboxLatestMoviesHeadData: DashboxTableHeadData = [
  { key: "_id", name: "ID" },
  { key: "title", name: "Title" },
  { key: "status", name: "Status" },
  { key: "rating", name: "Rating" },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard - Watch TV</title>

        {/* <script src="assets/js/admin.js"></script> */}
      </Helmet>
      <Main>
        {/* Page title */}
        <div className="col-12">
          <div className="main__title">
            <h2>Dashboard</h2>

            <Link to="/Movies/AddMovie" className="main__title-link">
              Add Movie
            </Link>
          </div>
        </div>

        {/* Stats */}
        <>
          <Stats
            statsTitle="Unique views this month"
            statsCount={78}
            StatsSvgIcon={UniqueViewsIcon}
          />

          <Stats
            statsTitle="Movies added this month"
            statsCount={32}
            StatsSvgIcon={ItemsAddedIcon}
          />

          <Stats
            statsTitle="New Comments"
            statsCount={25}
            StatsSvgIcon={NewCommentsIcon}
          />

          <Stats
            statsTitle="New Reviews"
            statsCount={21}
            StatsSvgIcon={RatingsIcon}
          />
        </>

        {/* Dashbox */}
        <>
          <Dashbox
            dashboxTitle="Top Movies"
            DashboxSvgIcon={RankIcon}
            stateName="movies"
            stateObject={"top_movies"}
            dashboxTableHeadData={dashboxTopMoviesHeadData}
            handleRefresh={() => {
              dispatch(getMovies());
            }}
            handleViewAll={() => {
              dispatch(sortMovies({ option: "rating", asc: true }));
              history.push("/Movies");
            }}
          />

          <Dashbox
            dashboxTitle="Latest Movies"
            DashboxSvgIcon={CatalogIcon}
            stateName="movies"
            stateObject="latest_movies"
            dashboxTableHeadData={dashboxLatestMoviesHeadData}
            handleRefresh={() => {
              dispatch(getMovies());
            }}
            handleViewAll={() => {
              dispatch(sortMovies({ option: "createdAt", asc: true }));
              history.push("/Movies");
            }}
          />
        </>
      </Main>
    </>
  );
};

export default Dashboard;
