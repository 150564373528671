import React, { FC } from "react";
import Logo from "../Logo/Logo";
import "./Sidebar.css";
import SidebarNav from "./SidebarNav/SidebarNav";
import SidebarUser from "./SidebarUser/SidebarUser";

type Props = {
  toggleSidebarOnSmallScreen: boolean;
};
const Sidebar: FC<Props> = ({ toggleSidebarOnSmallScreen }) => {
  return (
    <>
      {/*  sidebar  */}
      <div
        className={`sidebar ${
          toggleSidebarOnSmallScreen ? "sidebar--active" : ""
        }`}
      >
        {/* Sidebar logo  */}
        <Logo to="/Dashboard" styleClass="sidebar__logo" />

        {/* Sidebar User */}
        <SidebarUser />

        {/* Sidebar Nav */}
        <SidebarNav />

        {/* Sidebar Copyright */}
        <div className="sidebar__copyright">© WatchTV, 2021.</div>
        {/* end sidebar copyright */}
      </div>
      {/* end sidebar */}
    </>
  );
};

export default Sidebar;
