import React, { ChangeEvent, FC, useEffect, useState } from "react";

type Props = {
  image: FileList[0] | null;
  setImage: (file: FileList[0] | null) => void;
};

const ImageUploadField: FC<Props> = ({ image, setImage }) => {
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(
    null
  );

  useEffect(() => {
    if (!image) setImagePreview(null);
  }, [image]);

  const handleSetImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target!.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setImage(e.target.files[0]);
    }
  };

  return (
    <>
      {/* Image Upload */}

      <div className="col-12 col-sm-6 col-md-12">
        <div className="form__img">
          <label htmlFor="form__img-upload">Upload cover (190 x 270)</label>
          <input
            id="form__img-upload"
            name="form__img-upload"
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={handleSetImage}
          />
          <img id="form__img" src={imagePreview?.toString()} alt="" />
        </div>
      </div>
    </>
  );
};

export default ImageUploadField;
