import { SortOption } from "../../../../store/reducers/movies/type";
import { MovieSortDataKey, TableSortData } from "../../../type";
import "./FilterSort.css";

type Props = {
  sortData: TableSortData;
  currentSort: SortOption;
  handleSort: (key: MovieSortDataKey) => void;
};
const FilterSort = ({ sortData, handleSort, currentSort }: Props) => {
  return (
    <>
      <div className="filter" id="filter__sort">
        <span className="filter__item-label">Sort by:</span>

        <div
          className="filter__item-btn dropdown-toggle"
          role="navigation"
          id="filter-sort"
          data-toggle="dropdown"
          // aria-haspopup="true"
          // aria-expanded="false"
        >
          <input
            type="button"
            value={
              sortData[
                sortData.findIndex((sort) => sort.key === currentSort.option)
              ].name
            }
          />
          <span></span>
        </div>

        <ul
          className="filter__item-menu dropdown-menu scrollbar-dropdown"
          aria-labelledby="filter-sort"
        >
          {sortData.map((sortOption) => (
            <li key={sortOption.key} onClick={() => handleSort(sortOption.key)}>
              {sortOption.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default FilterSort;
