import { Errors, MovieFormData } from "../../type";

export const movieFormValidate = (movieFormData: MovieFormData) => {
  let err: Errors = [];

  //#region ::: Movie Data Validation Check
  if (!movieFormData.title) {
    err.push({ message: "Movie Title is required", show: true });
  }
  if (!movieFormData.description) {
    err.push({ message: "Movie Description is required", show: true });
  }
  if (!movieFormData.running_time) {
    err.push({ message: "Running Time is required", show: true });
  } else if (movieFormData.running_time < 1) {
    err.push({
      message: "Running Time must be more than one minute",
      show: true,
    });
  }
  if (!movieFormData.rating) {
    err.push({ message: "Movie Rating is required", show: true });
  } else if (movieFormData.rating < 1 || movieFormData.rating > 10) {
    err.push({
      message: "Movie rating must be between 1 and 10",
      show: true,
    });
  }
  if (movieFormData.genres.length < 1) {
    err.push({
      message: "Minimum of one Movie Genre is Required",
      show: true,
    });
  }
  if (movieFormData.tags.length < 1) {
    err.push({ message: "Minimum of one Movie Tag is Required", show: true });
  }
  if (!movieFormData.cover_image) {
    err.push({ message: "Movie Cover Image is required", show: true });
  }
  if (!movieFormData.backdrop_image) {
    err.push({ message: "Movie Backdrop Image is required", show: true });
  }
  //#endregion

  return err;
};
